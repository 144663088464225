import { graphql } from 'gatsby'
import React from 'react'
import { Container, PageTitle } from '../components'
import Layout from '../layouts'
import styled from '@emotion/styled'
import { theme } from '../assets/theme'
import { useTranslation } from '.'

const Poetry = props => {
  const { strapiPoetryList: item } = props.data
  const { t } = useTranslation(props?.pageContext?.locale)
  console.log(props)
  const from = {
    title: t('К стихам'),
    path: `/${props.pageContext?.locale}/poetries/${
      props.location?.search.split('=')[1]
    }`,
  }
  return (
    <Layout
      from={from}
      locale={props?.pageContext?.locale}
      menu={props?.pageContext?.menu}
      meta={props?.pageContext?.meta}>
      {item ? (
        <ViewContainer>
          <ViewInfo>
            <PageTitle>{item?.title}</PageTitle>
            <p>
              <pre>{item?.body}</pre>
            </p>
          </ViewInfo>
        </ViewContainer>
      ) : (
        ''
      )}
    </Layout>
  )
}

export default Poetry

export const query = graphql`
  query PoetryTemplate($id: String!, $locale: String!) {
    strapiPoetryList(id: { eq: $id }, locale: { eq: $locale }) {
      id
      title
      body
    }
  }
`

const ViewContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 4rem 0 !important;

  ${() => theme.mqMax('lg')} {
    padding: 2rem 10px !important;
  }
`

const ViewInfo = styled.div`
  p {
    line-height: 36px;

    pre {
      white-space: break-spaces;
    }
  }
`
